<template>
    <div class="Area">
        <main-header menuActive="statistics" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>당일배정 시설별 통계</h2>
                </div>
                <div class="row searchBtn">
                    <div class="pull-right">
                        <a class="btn8" @click="excelDownFunc" style="color:#fff">엑셀다운로드</a>
                    </div>
                </div>
                <div class="row">
                    <div class="form_box">
                        <div class="row no-pdd-top">
                            <div class="searchBox">

                                <div class="SearchDateBox">
                                    <label class="searchTitle">기간</label>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_start"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                    <span class="date_bar"> ~ </span>
                                    <div class="dataGroup">
                                        <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" v-model="sch_date_end"></datepicker>
                                        <span class="icon_calendar"></span>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row text-center">
                            <a class="btn7 mrg-right-10" @click="searchListFunc">조회</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="table-responsive">
                        <table class="table table-profile">
                            <tbody>
                            <tr>
                                <th class="field text-center font-weight-bold">일자</th>
                                <th class="field text-center font-weight-bold">생치</th>
                                <th class="field text-center font-weight-bold">구생치</th>
                                <th class="field text-center font-weight-bold">기타</th>
                                <th class="field text-center font-weight-bold">병원</th>
                                <th class="field text-center font-weight-bold">합계</th>
                            </tr>
                            <tr v-for="(data, index) in dataList" :key="index">
                                <td class="text-center">{{ data.fregDate }}</td>
                                <td class="text-center text-link" @click="searchDetailFunc('생치', data.fregDate, 'G0401')">{{ data.dayAssign1 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('구생치', data.fregDate, 'G0408')">{{ data.dayAssign2 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('기타', data.fregDate, 'G0409')">{{ data.dayAssign3 | comma }} 명</td>
                                <td class="text-center text-link" @click="searchDetailFunc('병원', data.fregDate, 'G0402')">{{ data.dayAssign4 | comma }} 명</td>
                                <td class="text-center text-link" >{{ data.dayAssign5 | comma }} 명</td>
                            </tr>
                            </tbody>
                            <tfoot>
                            <tr>
                                <th class="field text-center font-weight-bold">총계</th>
                                <td class="text-center">{{ sum_G0401 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0408 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0409 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0402 | comma }} 명</td>
                                <td class="text-center">{{ sum_G0410 | comma }} 명</td>
                            </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

export default {
    name: 'Area',
    data() {
        return {
            ko : ko,
            sch_date_start : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth(), 1)).format('YYYY-MM-DD'),
            sch_date_end : this.$moment(new Date(new Date().getFullYear(), new Date().getMonth()+1, 0)).format('YYYY-MM-DD'),
            dataList : [],

            sum_G0401 : 0,
            sum_G0408 : 0,
            sum_G0409 : 0,
            sum_G0402 : 0,
            sum_G0410 : 0
        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
    },
    // $el 생성전
    created() {
        this.checkLogin();
    },
    // $el 생성후
    mounted() {
        if (this.$route.params.sch_date_start){
            this.sch_date_start = this.$route.params.sch_date_start;
            this.sch_date_end = this.$route.params.sch_date_end;

            this.searchListFunc()
        }else{
            this.searchListFunc()
        }
    },
    methods: {
        // 리스트 검색
        searchListFunc(){
            let params = `{"fromRegDt": "${this.dateToString(this.sch_date_start)}", "toRegDt": "${this.dateToString(this.sch_date_end)}"}`;
            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/dayAssign?params=' + queryString, null, 'searchListFuncResult');

            /*
            let formData = new FormData();
            formData.append('api_name', 'statisticsDateassign');
            formData.append('sch_date_start', this.$moment(this.sch_date_start).format('YYYY-MM-DD'));
            formData.append('sch_date_end', this.$moment(this.sch_date_end).format('YYYY-MM-DD'));
            formData.append('f_admin_id', this.userDataAdminId);

            this.ajaxPost('', formData,'searchListFuncResult');
            */
        },
        searchListFuncResult(response) {
            let result = response;
            this.dataList = result.list;

            let sum_G0401 = 0
            let sum_G0408 = 0
            let sum_G0409 = 0
            let sum_G0402 = 0
            let sum_G0410 = 0

            result.list.forEach(function (item) {
                sum_G0401 = parseInt(item.dayAssign1) +  sum_G0401;
                sum_G0408 = parseInt(item.dayAssign2) +  sum_G0408;
                sum_G0409 = parseInt(item.dayAssign3) +  sum_G0409;
                sum_G0402 = parseInt(item.dayAssign4) +  sum_G0402;
            });

            this.sum_G0401 = sum_G0401;
            this.sum_G0408 = sum_G0408;
            this.sum_G0409 = sum_G0409;
            this.sum_G0402 = sum_G0402;
            this.sum_G0410 = sum_G0401 + sum_G0408 + sum_G0409 + sum_G0402;
        },
        excelDownFunc(){
            console.log("엑셀다운로드");
            /*
            let url=this.rootUrl+'/_excel/excel_statistics_assign_fasility.php?1=1';
            if(this.sch_date_start && this.sch_date_end){
                url += '&sch_date_start='+this.$moment(this.sch_date_start).format('YYYY-MM-DD');
                url += '&sch_date_end='+this.$moment(this.sch_date_end).format('YYYY-MM-DD');
            }
            this.excelExport(url);
            */

            let params = `{
                "fromRegDt": "${this.dateToString(this.sch_date_start)}", 
                "toRegDt": "${this.dateToString(this.sch_date_end)}"
            }`;

            let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
            this.ajaxGet('nocStatistics/dayAssign?params=' + queryString, null, 'downloadExcel');

        },
        downloadExcel(response) {
            if (!response || !response.list || response.list.length === 0) {
                console.error('Empty or undefined dataList in response');
                return;
            }

            let dataList = response.list; // 데이터 가져오기
            console.log(dataList);
            let excelData = [];

            let totaldayAssign1 = 0; // 총계 초기화
            let totaldayAssign2 = 0; // 총계 초기화
            let totaldayAssign3 = 0; // 총계 초기화
            let totaldayAssign4 = 0; // 총계 초기화
            let totaldayAssign5 = 0; // 총계 초기화

            // 엑셀 헤더 추가
            excelData.push(['일자', '생치', '구생치', '기타', '병원', '합계']);

            // 엑셀 데이터 추가
            dataList.forEach((data, index) => {
                excelData.push([
                    data.fregDate, 
                    data.dayAssign1, 
                    data.dayAssign2, 
                    data.dayAssign3, 
                    data.dayAssign4, 
                    data.dayAssign5 
                ]);

                // 각 열의 데이터를 합산하여 총계에 누적
                totaldayAssign1 += data.dayAssign1;
                totaldayAssign2 += data.dayAssign2;
                totaldayAssign3 += data.dayAssign3;
                totaldayAssign4 += data.dayAssign4;
                totaldayAssign5 += data.dayAssign5;

            });

            // 총계 행 추가
            excelData.push(['총계', totaldayAssign1, totaldayAssign2, totaldayAssign3, totaldayAssign4, 
            totaldayAssign5]); 
            

            console.log(excelData);

            // 엑셀 라이브러리 가져오기
            const XLSX = require('xlsx');

            // 엑셀 파일 생성
            let ws = XLSX.utils.aoa_to_sheet(excelData);
            let wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

            // 엑셀 파일 다운로드
            XLSX.writeFile(wb, '병상배정통계_당일배정별.xlsx');
        },
        // 상세페이지
        searchDetailFunc(title, date, idx){
            let data={};
            data.sch_date_start = this.sch_date_start;
            data.sch_date_end = this.sch_date_end;
            data.title = title;
            data.date = date;
            data.idx = idx;
            data.sch_type = 'assign';

            this.$router.push({ name: 'AdminStatisticsDetail', params: {data} })
        },
        dateToString(dateString) {
            // const dateString = "Fri Jan 01 2021 09:00:00 GMT+0900 (한국 표준시)";
            const date = new Date(dateString); // Date 객체 생성
            const formattedDate = `${date.getFullYear()}-${padZero(date.getMonth() + 1)}-${padZero(date.getDate())}`; // 년, 월, 일을 추출하여 원하는 형식으로 조합

            // 월과 일이 한 자리 수일 경우 앞에 0을 붙이는 함수
            function padZero(num) {
                return num < 10 ? '0' + num : num;
            }

            return formattedDate;
        },
    }
}
</script>

<style scoped>
</style>
